import { map, find, get, isEmpty } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  ErrorBoundary,
  LabeledIconInput,
  Popup,
  SearchIcon,
  Theme,
  themeOptions,
} from '@commonsku/styles';
import { BASE_ZINDEX } from '../popup-factory';
import SelectPSSkuByColorSize from './SelectPSSkuByColorSize';
import { SelectSkuOptionsPopup, mapStateToProps, mapDispatchToProps, } from './SelectSkuOptionsPopup';
import { createGlobalStyle } from './helpers';
import { closePopup } from '../actions/popup';

const GlobalStyle = createGlobalStyle();

export function getDefaultSelectedCostSku(item_skus, default_sku_id) {
  return get(
    find(item_skus || [], v => v.id === default_sku_id),
    ['sku']
  );
}

class SelectPSSkuOptionsByColorSizePopup extends SelectSkuOptionsPopup {
  constructor(props) {
    super(props);

    this.state = {
      search_query: '',
      selectedCostSku: this.getDefaultSelectedCostSku(),
    };

    this.onSelectCost = this.onSelectCost.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  getItemSkus() {
    return (this.props.item_skus || []).concat(
      (this.props.product?.skus || []).map(v => ({...v, id: v.product_sku_id}))
    );
  }

  getDefaultSelectedCostSku() {
    return getDefaultSelectedCostSku(
      this.getItemSkus(),
      this.props.default_sku_id
    );
  }

  onSelectCost(sku) {
    this.setState({ selectedCostSku: sku });
  }

  async handleClose(e) {
    e && e.preventDefault();

    const { item_skus } = this.props;
    const { selectedCostSku } = this.state;
    if (selectedCostSku && this.props.onUpdate) {
      const product_sku_ids = await this.loadProductSkuIds(
        find(item_skus, { sku: selectedCostSku }) ? [selectedCostSku] : map(item_skus, 'sku')
      );
      if (!isEmpty(product_sku_ids)) {
        this.props.onUpdate(product_sku_ids[0]);
      }
    }
    this.props.dispatch(closePopup());
  }

  render() {
    const {
      product_id,
      item_id,
      api_name,
      item_skus,
      sku,
      index,
      selectTabIndex=0,
    } = this.props;
    const selectedSkus = item_skus.map(s => s.sku);
    const selectedCostSku = this.state.selectedCostSku || this.getDefaultSelectedCostSku();

    return (
      <Theme theme={themeOptions}>
        <GlobalStyle theme={themeOptions} />
        <ErrorBoundary>
          <Popup
            title={'Product Options'}
            onClose={this.handleClose}
            zIndex={BASE_ZINDEX + index}
            overlayZIndex={BASE_ZINDEX + index}
            controls={<>
              <LabeledIconInput
                label=''
                Icon={<SearchIcon color='#D0D7DC' style={{ marginTop: '30%', }} />}
                className='sku-options-search-query'
                placeholder="Filter"
                value={this.state.search_query}
                onChange={e => this.setState({ search_query: e.target.value })}
                style={{
                  margin: 0,
                  marginRight: 10,
                  width: 225,
                  height: 54,
                }}
                iconColor='#D0D7DC'
                iconLabelStyles={{ height: '100%', background: 'none' }}
                containerStyle={{ display: 'inline-block', }}
              />
              <Button variant={'cta'} onClick={this.handleClose}>Done</Button>
            </>}
          >
            <SelectPSSkuByColorSize
              product_id={product_id}
              multi={true}
              selectAll={true}
              value={selectedSkus}
              onSkusSelected={this.onSelectSkus}
              maxHeight='100%'
              search_query={this.state.search_query}
              selectTabIndex={selectTabIndex}
              item_id={item_id}
              api_name={api_name}
              sku={sku}
              canToggleValueOnFetch={false}
              selectedCostSku={selectedCostSku}
              onSelectCost={this.onSelectCost}
            />
          </Popup>
        </ErrorBoundary>
      </Theme>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectPSSkuOptionsByColorSizePopup);
