import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get, map, difference, isEmpty, filter } from 'lodash';

import { BASE_ZINDEX } from '../popup-factory';

import { getItemSkus } from '../selectors/items';

import { closePopup } from '../actions/popup';
import { createFetchProduct } from '../actions/product';
import { createAddItemSku, createDeleteItemSku, createDeleteAllItemSkus } from '../actions';
import { loadProductSkus } from '../helpers/ps_sku';
import SelectSku from './SelectSku';

export class SelectSkuOptionsPopup extends Component {

  constructor(props) {
    super(props);

    ['onSelectSkus', 'handleClose'].forEach(
      method => this[method] = this[method].bind(this)
    );
  }

  async onSelectSkus(value) {
    const { product, dispatch, item_id, item_skus } = this.props;
    const existing = map(item_skus, 'sku');
    const deleted = difference(existing, value);
    const added = difference(value, existing);
    if (deleted.length > 0 && deleted.length === item_skus.length) {
      dispatch(createDeleteAllItemSkus(item_id));
    } else {
      filter(item_skus, ({ sku }) => deleted.indexOf(sku) > -1).forEach(
        d => dispatch(createDeleteItemSku(d.item_sku_id))
      );
    }
    const product_skus = await this.loadProductSkuIds(added);
    if (!isEmpty(product_skus)) {
      dispatch(createAddItemSku(item_id, product_skus));
    }
  }

  async loadProductSkuIds(skus) {
    const result = await this.loadProductSkus(skus);
    return map(result, (sku) => get(sku, ['product_sku_id']));
  }

  async loadProductSkus(skus) {
    const { product, loadProduct } = this.props;
    const result = await loadProductSkus(product, loadProduct, skus);
    return result;
  }

  handleClose(e) {
    e.preventDefault();
    this.props.dispatch(closePopup());
  }

  render() {
    const { product_id, item_skus, index } = this.props;

    return (
      <div className="reveal large" style={{ overflowY: 'visible', display: 'block', zIndex: BASE_ZINDEX + index, height: 'initial' }} role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3>Pick Sku Options</h3>
          </div>
        </div>
        <div className="row full-width">
          <div className="small-12 columns">
            <SelectSku
              product_id={product_id}
              multi={true}
              selectAll={true}
              value={item_skus.map(s => s.sku)}
              onSkusSelected={this.onSelectSkus}
              maxHeight='30rem'
            />
          </div>
        </div>
        <button
          className="button alt"
          aria-label="Close modal"
          type="button"
          onClick={this.handleClose}
          style={{
            position: 'absolute',
            right: '1rem',
            top: '0.5rem'
          }}>
            Close
          </button>
      </div>
    );
  }
}

export const mapStateToProps = (state, ownProps) => ({
  item_skus: getItemSkus(state, ownProps),
  product: ownProps.product || state.entities.products[ownProps.product_id]
});

export const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatch,
  loadProduct: (data) => dispatch(createFetchProduct(ownProps.product_id, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectSkuOptionsPopup);
